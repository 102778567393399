<template>
    <v-card class="mx-0 my-3 fill-height" height="auto" v-if="join !== null" flat>
        <v-list-item three-line>
            <v-list-item-avatar tile size="80">
                <v-img src="@/assets/images/avatars/profil.svg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    <router-link :to="{
                    name: 'user.views', params: { f_id: o_md5(this.join.user.id), ids:
                        this.join.user.id } }">{{ join.user.firstname }}</router-link>
                </v-list-item-title>
                <div class="d-flex flex-wrap flex-row">
                    <v-icon small>{{ icons.mdiWeb }}</v-icon>
                    <span class="my-auto ml-1 mb-1 caption">{{ join.user.email == null ? "##" : join.email
                    }}</span>
                </div>
                <!-- <v-row align="start" class="mx-0">
                    <v-rating :value="4.5" color="primary" dense half-increments readonly size="14"></v-rating>
                    <div class="grey--text ml-4">4.5 (413)</div>

                </v-row> -->
                <!-- <v-list-item-subtitle>dddd</v-list-item-subtitle> -->
            </v-list-item-content>

            <v-list-item-action class="align-center justify-space-between">

            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>


<script>
// eslint-disable-next-line object-curly-newline
import { mdiMapMarkerOutline, mdiWeb } from '@mdi/js'
import { ref } from '@vue/composition-api'
var md5 = require("md5")

export default {
    props: {
        join: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        viewUserInfos() {
            this.$router.push({ name: "user.views", params: { f_id: md5(this.join.user.id), ids: this.join.user.id } })
        },
        o_md5(root_link){
            return md5(root_link)
        }
    },
    setup(props) {
        const icons = { mdiMapMarkerOutline, mdiWeb }

        return {
            icons,
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';
</style>
