<template>
    <div>
        <!-- Page heading -->
        <header class="bg-gray-50">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                <div class="min-w-0 flex-1">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol role="list" class="flex items-center space-x-4">
                            <li>
                                <div>
                                    <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-700">Home</a>
                                </div>
                            </li>
                            <li>
                                <div class="flex items-center">
                                    <svg class="h-5 w-5 flex-shrink-0 text-gray-400"
                                        x-description="Heroicon name: mini/chevron-right"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                    <a href="#"
                                        class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Networking</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </header>
        <!-- <card-header :header="headers"></card-header> -->
        <section class="px-0 flex flex-row">
            <article class="col-md-8">
                <ul class="nav-dash-tab">
                    <li class="text-center font-bold uppercase" v-for="(tab, i) in tabs" :key="i"
                        v-on:click="toggleTabs(i + 1)"
                        v-bind:class="{ 'nav-dash-tab-item': openTab !== (i + 1), 'nav-dash-tab-item-active': openTab === (i + 1) }">
                        {{ tab.title }}
                        <!-- {{ i }} -->
                    </li>
                </ul>

                <div class="relative flex flex-col min-w-0 break-words bg-white w-full mt-6  rounded">
                    <div class="px-4 py-5 flex-auto">
                        <div class="tab-content tab-space">
                            <div v-for="tab in 3" :key="tab"
                                v-bind:class="{ 'hidden': openTab !== tab, 'block': openTab === tab }">
                                <networking-table-data :stab="tab + 1"></networking-table-data>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <aside class="col-md-4">
                <invite-some-home></invite-some-home>
            </aside>
        </section>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiEye, mdiShareAll, mdiAccountNetwork } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import NetworkingTableData from '@/views/dashboard/networking/components/NetworkingTableData.vue'
import HeaderTab from '@/views/dashboard/components/HeaderTab.vue'
import InviteSomeHome from './InviteSomeHome.vue'
import CardHeader from '@/views/dashboard/components/CardHeader.vue'


// import md5 from '@md5'
var md5 = require("md5")
export default {
    components: {
        NetworkingTableData,
        HeaderTab,
        InviteSomeHome,
        CardHeader
    },
    data() {
        return {
            headers: {
                titlepage: 'NETWORKING',
                icon: null,
            },
            isDataLoading: true,
            tab: 0,
            headertab: {
                initial: "Join",
                icon: mdiAccountNetwork,
                title: "Manage your network colaboration"
            },
            openTab: 1,
            tabs: [
                { title: 'My network', icon: mdiShareAll },
                { title: 'Received Invitations', icon: mdiEye },
                { title: 'Sent Invitations', icon: mdiShareAll },
            ],
            tabinvite: { title: 'Invite someone', icon: mdiEye }
        };
    },
    methods: {
        toggleTabs: function (tabNumber) {
            this.openTab = tabNumber
        }
    },
    setup(props) {
        const gettingData = props.listData

        const title = props.title
        const icons = { mdiMagnify, mdiEye, mdiShareAll, mdiAccountNetwork }
        let search = ref('')
        const headers = [
            { text: 'No', value: 'noIndex' },
            {
                text: 'Name',
                align: 'start',
                sortable: true,
                value: 'user',
            },
            { text: 'Status', value: 'status' },
            // { text: 'Date', value: 'date' },
            {
                text: 'More actions',
                // align: 'center',
                value: 'actions', sortable: false
            },
        ]
        return {
            gettingData,
            title,
            icons,

            search,
            headers
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/tabs.scss';


.row-pointer>>>tbody tr :hover {
    cursor: pointer;
}

.whitetile {
    color: white;
}
</style>