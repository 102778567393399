<template>
    <v-card>
        <v-card-title class="d-flex flex-row">
            <span>Recommanded for you</span>
            <v-spacer></v-spacer>
            <v-btn icon>
                <v-icon>{{ icons.mdiDotsHorizontal }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card class="mx-auto" max-width="344" v-if="loadinguser === true" flat>
            <v-container style="height: 400px;">
                <v-row class="fill-height" align-content="center" justify="center">
                    <v-col class="text-subtitle-1 text-center mx-auto" cols="7">
                        Getting similar professionnal
                    </v-col>
                    <v-col cols="6">
                        <v-progress-circular :width="3" color="secondary" indeterminate></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card-text v-else>
            <div v-if="invitelist.lenght <= 0" class="align-center text-center">No recommandation available</div>
            <invite-some-one v-else v-for="(item, i) in invitelist" :key="i" :user="item"></invite-some-one>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-btn text color="secondary" class="ma-4" :to="{name: 'professional'}">View more</v-btn>
    </v-card>
</template>

<script>
import { mdiDotsHorizontal } from '@mdi/js';
import InviteSomeOne from './components/InviteSomeOne.vue';
import { Drequest } from '@/plugins/Drequest';
export default {
    components: {
        InviteSomeOne
    },
    data() {
        return {
            loadinguser: true,
            invitelist: [],
            listcolaborator: [],
            icons: { mdiDotsHorizontal }
        }
    },
    mounted() {
        this.loadinguser = true
        Drequest.api(`colaboration.invitesomeone?owner=${this.$store.getters.getUser.id}`)
            .get((response) => {
                if (response.success === true) {
                    this.loadinguser = false
                    var tmp = {}
                    response.listEntity.forEach((value, index) => {
                        tmp = {
                            name: value.firstname,
                            id: value.id,
                            about: value.about
                        }
                        this.invitelist.push(tmp)
                    });
                    console.log(this.invitelist)
                }
                else {
                    this.loadinguser = false
                }
            })
            .catch((err) => {
                this.loadinguser = false
            });

    },
    methods: {

    }
}
</script>